<template>
  <div v-if="list && list.length" class="tape">
    <a @click.prevent="swiper.slidePrev()" class="tape-slider__control tape-slider__prev">
      <ArrowLeft />
    </a>
    <div class="tape-slider">
      <swiper ref="swiper" :options="swiperOptions">
        <swiper-slide v-for="(item, index) in list" :key="index">
          <a :href="item.link" class="tape-item" target="_blank">
            <span class="tape-item__icon" v-html="item.icon"></span>
            <span class="tape-item__title">{{ item.title }}</span>
          </a>
        </swiper-slide>
      </swiper>
    </div>
    <a @click.prevent="swiper.slideNext()" class="tape-slider__control tape-slider__next">
      <ArrowRight />
    </a>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import ArrowLeft from "@/components/svg/ArrowLeft.vue";
import ArrowRight from "@/components/svg/ArrowRight.vue";

export default {
  name: "HomePageTapeSlider",
  props: {
    list: Array,
  },
  components: {
    Swiper,
    SwiperSlide,
    ArrowLeft,
    ArrowRight,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      loading: false,
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 32,
        loop: false,
        // loopFillGroupWithBlank: false,
        clickable: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        breakpoints: {
          460: {
            slidesPerView: 1.5,
          },
          520: {
            slidesPerView: 2,
          },
          680: {
            slidesPerView: 2.5,
          },
          800: {
            slidesPerView: 3,
          },
          920: {
            slidesPerView: 3.5,
          },
          1050: {
            slidesPerView: 4,
          },
          1200: {
            slidesPerView: 4.5,
          },
          1300: {
            slidesPerView: 5,
          },
        },
        preloadImages: false,
        lazy: {
          loadOnTransitionStart: true,
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.swiper.$swiper;
    },
  },
  mounted() {
    if (this.list && this.list.length >= 2) {
      this.swiper.slideTo(0, 1000, false);
    }
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
@import "~@/styles/mixins/textcut.styl"
.tape {
  position relative
  padding 12px 32px
  display flex
  align-items center
  gap 32px
  border-bottom 1px solid var(--color_gray_divider)
  border-top 1px solid var(--color_gray_divider)
  +below(1200px) {
    padding 24px
  }
  +below(420px) {
    gap 8px
  }
  +below(380px) {
    padding 16px
  }

  &-slider {
    width 100%
    & .swiper-wrapper{
      display flex
      align-items center
    }
  }

  &-item {
    display flex
    gap 12px
    color var(--main_color)
    align-items center

    &__icon {
      padding 10px
      background var(--color_gray_light)
      border-radius 50%
      display flex
      flex-shrink 0
      line-height 1

      svg {
        svg(--main_color)
        width 20px
        height 20px
      }
    }

    &__title {
      text-align center
      textcut(3)
      word-break break-word
      +below(380px) {
        font-size .875em
        line-height 20px
      }
    }
  }


  &-slider__control {
    display flex
    flex-shrink 0

    &:hover {
      .icon svg {
        svg(var(--main_color))
      }
    }

    .icon {
      width 24px
      height 24px
      +below(380px) {
        width: 20px
        height: 20px
      }

      svg {
        svg(var(--color_dark))
      }
    }
  }
}
</style>
