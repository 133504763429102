<template>
  <section class="section city-dev">
    <h2 class="title">Участвуйте в развитии города</h2>
    <ul class="city-dev__tabs">
      <li v-for="(item, i) in city_devs" :key="i" class="city-dev__tab" :class="{ active: activeTab === i }">
        <a @click.prevent="setActive(i)" href="#">{{ item.title }}</a>
      </li>
    </ul>
    <swiper class="city-dev__slider" ref="swiper" :options="swiperOptions">
      <swiper-slide v-for="(item, index) in city_devs" :key="index">
        <div :class="{ 'city-dev__slide--active': activeTab === index }" class="city-dev__slide">
          <router-link
            :to="item.route"
            class="img__container"
            :data-bg="item.img"
            :data-bg-mobile="item.img_mobile"
          >
            <div class="city-dev__slide-info" :class="{ 'city-dev__slide-info--white': item.white }">
              <span class="city-dev__slide-title"> {{ item.title }}</span>
              <span class="city-dev__slide-description">{{ item.description }}</span>
              <button class="btn btn-blue">{{ item.btnTitle }}</button>
            </div>
            <img
              :data-src="item.img"
              src="/public/images/no-image.png"
              :alt="item.img"
              class="city-dev__slide-img swiper-lazy"
            />
            <img
              :data-src="item.img_mobile"
              src="/public/images/no-image.png"
              :alt="item.img_mobile"
              class="city-dev__slide-img city-dev__slide-img--mobile swiper-lazy"
            />
            <div class="swiper-lazy-preloader"></div>
          </router-link>
        </div>
      </swiper-slide>
    </swiper>
    <div id="city_devs_bullets" class="slider__bullets" slot="pagination"></div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";

export default {
  name: "HomeCityDevComponent",
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      activeTab: 0,
      city_devs: [
        {
          title: "Общественные\n обсуждения",
          description: "Принимайте участие в голосованиях по вопросам городского развития.",
          img: "/public/images/city_devs/pd.png",
          img_mobile: "/public/images/city_devs/pd_mobile.png",
          route: { name: "pd" },
          btnTitle: "Принять участие",
        },
        {
          title: "Общественный\n контроль",
          description: "Принимайте участие в голосованиях по вопросам городского развития.",
          img: "/public/images/city_devs/sc.png",
          img_mobile: "/public/images/city_devs/sc_mobile.png",
          route: { name: "sc" },
          btnTitle: "Принять участие",
        },
        {
          title: "Городские идеи",
          description:
            "Принимайте участие в жизни города. Предлагайте ваши идеи, проекты и пожелания. Лучшие предложения пройдут все шаги от идеи до реализации.",
          img: "/public/images/city_devs/ic.png",
          img_mobile: "/public/images/city_devs/ic_mobile.png",
          route: { name: "ic" },
          btnTitle: "Предложить идею",
          white: true,
        },
      ],
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 24,
        loop: false,
        clickable: true,
        speed: 800,
        watchOverflow: true,
        slideToClickedSlide: true,
        breakpoints: {
          640: {
            slidesPerView: 1.1,
          },
          680: {
            slidesPerView: 1.2,
          },
          840: {
            slidesPerView: 1.48,
            spaceBetween: 32,
          },
        },
        pagination: {
          el: "#city_devs_bullets",
          clickable: true,
        },
        preloadImages: false,
        lazy: {
          loadOnTransitionStart: true,
        },
        on: {
          slideChange: () => {
            if (!this.swiper.isEnd) {
              this.setActive(this.swiper.activeIndex);
            }
          },
          reachEnd: () => {
            this.setActive(this.swiper.slides.length - 1);
          },
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.swiper.$swiper;
    },
  },
  methods: {
    setActive(tab) {
      this.activeTab = tab;
      this.swiper.slideTo(tab);
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
.city-dev {
  &__tabs {
    display flex
    gap 32px
    margin-bottom 30px
    overflow auto
    +below(640px) {
      gap 16px
    }
  }

  &__tab {
    flex-shrink 0
    display flex
    flex-direction column
    font-size: 1.125em;
    line-height: 28px
    +below(990px) {
      font-size: 0.875em
      line-height: 20px;
    }

    &.active {
      &:after {
        content ''
        display block
        margin-top 8px
        width 100%
        height 3px
        background-color var(--color_blue)
        animation-name line
        animation-duration 0.3s
        animation-timing-function: cubic-bezier(0.51, 0.92, 0.24, 1.15);
        +below(768px) {
          margin-top: 4px;
        }
      }

      > a {
        color var(--color_black)
      }
    }

    > a {
      white-space nowrap
      color var(--color_gray_dark)

      &:hover {
        color var(--color_black)
      }
    }
  }

  &__slide {
    ratio(1024, 423)
    position relative
    opacity 0.32
    transition 0.3s
    +below(540px) {
      ratio(380, 423)
    }
    +below(420px) {
      ratio(280, 423)
    }

    &--active {
      opacity 1
    }

    &-info {
      position relative
      z-index 2
      display flex
      flex-direction column
      gap 24px
      align-items flex-start
      height 100%
      padding 40px 32px
      +below(1200px) {
        gap 16px
      }
      +below(1050px) {
        padding 32px
        font-size 0.875em
      }
      +below(900px) {
        gap 12px
        padding 24px
      }
      +below(990px) {
        padding 16px
      }
      +below(540px) {
        padding 20px
        font-size 1em
      }

      &--white {
        color var(--color_white)
      }

      &:not(&--white) {
        color var(--color_black)
      }
    }

    &-title {
      font-weight: 500;
      font-size: 2.25em
      line-height: 1
      white-space pre-line
      +below(1200px) {
        font-size 1.5em
      }
      +below(990px) {
        font-size 1.25em
      }
      +below(540px) {
        font-size 1.5em
      }
    }

    &-description {
      max-width 50%
      //max-width 480px
      +below(1250px) {
        max-width 60%
      }
      +below(640px) {
        max-width 70%
      }
      +below(420px) {
        max-width 80%
      }
    }

    .btn {
      margin-top auto
      text-transform none
      +below(900px) {
        min-height 44px
      }
      +below(420px) {
        width 100%
        max-width 208px
        align-self center
      }
    }

    &-img {
      position absolute
      left 0
      right 0
      top 0
      bottom 0
      z-index 1

      &:not(&--mobile) {
        +below(540px) {
          display none
        }
      }

      &--mobile {
        +above(541px) {
          display none
        }
      }
    }
  }

  .slider__bullets {
    +above(541px) {
      display none
    }
  }
}
</style>
