<template>
  <div class="messages__slider" v-if="messages && messages.length">
    <swiper :options="options">
      <swiper-slide v-for="(item, index) in messages" :key="index">
        <div class="slide__mobile">
          <router-link
            :to="{ name: 'news_item', params: { id: item.id, url: item.link } }"
            class="slide__messages-item"
          >
            <span class="slide__messages-bottom">
              <span v-if="item.category && item.category.title" class="slide__messages-category">
                {{ item.category.title }}
              </span>
              <time class="slide__news-date" :datetime="item.date_publication | robotDate">
                {{ item.date_publication | humanDateRu }}
              </time>
            </span>
            <span v-if="item.title" class="slide__messages-title">{{ item.title }}</span>
          </router-link>
        </div>
      </swiper-slide>
    </swiper>
    <div id="messages__bullets" class="slider__bullets" slot="pagination"></div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";

export default {
  name: "HomePageMessagesSliderMobile",
  props: {
    messages: Array,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      loading: false,
      options: {
        slidesPerView: 1.2,
        loop: false,
        clickable: true,
        speed: 800,
        spaceBetween: 16,
        watchOverflow: true,
        breakpoints: {
          480: {
            slidesPerView: 2,
            spaceBetween: 32,
          },
          700: {
            slidesPerView: 3,
          },
          860: {
            slidesPerView: 4,
          },
          1150: {
            slidesPerView: 5,
          },
          1300: {
            slidesPerView: 6,
          },
        },
        pagination: {
          el: "#messages__bullets",
          clickable: true,
        },
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      },
    };
  },
};
</script>

<style lang="stylus">
.messages__slider{
  & .swiper-wrapper{
    display flex
    justify-content space-between
  }

  .slider__bullets {
    margin-top 0
  }
}
</style>
