<template>
  <div class="col-12 messages__side" v-if="messages && messages.length">
    <div class="title__header">
      <h2 class="title">Сообщения</h2>
      <a @click.prevent="setTypeCode($store.state.type.CODE_MESSAGES)" href="#" class="news__side-show-all">
        <span>Все сообщения</span>
      </a>
    </div>
    <HomePageMessagesSlider :messages="messages" />
  </div>
</template>

<script>
import HomePageMessagesSlider from "./MessagesSlider.vue";

export default {
  name: "HomePageMessagesList",
  props: {
    messages: Array,
  },
  methods: {
    /**
     * Фильтр новостей по определенному типу
     * @param code
     */
    setTypeCode(code) {
      this.$store.state.newsTypeCode = code;
      this.$router.push({ name: "news" });
    },
  },
  components: {
    HomePageMessagesSlider,
  },
};
</script>
