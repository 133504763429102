<template>
  <div v-if="news && news.length" class="slider__mobile slider__mobile-news">
    <swiper :options="options">
      <swiper-slide v-for="(item, index) in news" :key="index">
        <div class="slide__mobile">
          <router-link
            :to="{ name: 'news_item', params: { id: item.id, url: item.link } }"
            class="slide__news-item"
          >
            <div class="slide__news-img">
              <div class="img__container">
                <img :src="item.head_img | image($store.state.api)" :alt="item.head_img | image_alt" />
              </div>
            </div>
            <span v-if="item.title" class="slide__news-title">{{ item.title }}</span>
            <span class="slide__news-bottom">
              <span v-if="item.category && item.category.title" class="slide__news-category">
                {{ item.category.title }}
              </span>
              <span class="slide__news-date">
                <time :datetime="item.date_publication | robotDate">
                  {{ item.date_publication | humanDateRu }}
                </time>
              </span>
            </span>
          </router-link>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";

export default {
  name: "HomePageNewsSliderMobile",
  props: {
    news: Array,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      loading: false,
      options: {
        slidesPerView: 1.1,
        loop: false,
        clickable: true,
        speed: 800,
        spaceBetween: 16,
        breakpoints: {
          360: {
            slidesPerView: 1.5,
          },
          440: {
            slidesPerView: 1.8,
          },
          480: {
            slidesPerView: 2,
          },
        },
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      },
    };
  },
};
</script>
