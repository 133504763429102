<template>
  <div v-if="banners && banners.length" class="col-12 banners-under-messages banners__side">
    <div class="slider-wrapper slider-wrapper__banners">
      <div class="slider">
        <swiper ref="smallSwiper" :options="swiperOptions">
          <swiper-slide v-for="(item, index) in banners" :key="index">
            <div class="slide">
              <div v-if="item.head_img" class="slider__img">
                <a :href="item.link" class="img__container" target="_blank">
                  <img
                    :data-src="item.head_img | image($store.state.api)"
                    src="/public/images/no-image.png"
                    :alt="item.head_img | image_alt"
                    class="swiper-lazy"
                  />
                  <div class="swiper-lazy-preloader"></div>
                </a>
              </div>
              <WidgetOG class="slider__widget" v-else-if="item.iframe && isMounted" :html="item.iframe" />
            </div>
          </swiper-slide>
        </swiper>
        <div id="under_messages_banners_bullets" class="slider__bullets" slot="pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import WidgetOG from "components/WidgetOG.vue";

export default {
  name: "HomePageBannersSlider",
  props: {
    banners: Array,
  },
  components: {
    WidgetOG,
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      isMounted: false,
      swiperOptions: {
        slidesPerView: 1,
        loop: false,
        clickable: true,
        speed: 800,
        watchOverflow: true,
        breakpoints: {
          380: {
            slidesPerView: 1.1,
          },
          480: {
            slidesPerView: 1.2,
          },
          768: {
            slidesPerView: 1.5,
          },
          1100: {
            slidesPerView: 2,
          },
        },
        pagination: {
          el: "#under_messages_banners_bullets",
          clickable: true,
        },
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        preloadImages: false,
        lazy: {
          loadOnTransitionStart: true,
        },
      },
    };
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>
