<template>
  <div class="lm">
    <div class="lm__header">
      <LogoIcon />
      <span>Восстановление пароля</span>
      <button type="button" @click="$emit('close')" class="lm__close" v-if="!loading">
        <CloseIcon />
      </button>
    </div>
    <div class="lm__body">
      <form @submit.prevent="submitForm" class="lm__form">
        <div class="lm__form-item" :class="{ error: !!form.email.message }">
          <label for="email">Логин</label>
          <div class="lm__form-item-input">
            <input
              v-model="form.email.value"
              id="email"
              type="email"
              placeholder="Введите E-mail"
              autofocus
              autocomplete="off"
            />
            <span class="lm__form-item-message" v-if="form.email.message">{{ form.email.message }}</span>
          </div>
        </div>
        <div class="lm__form-item form__item-password" :class="{ error: !!form.password.message }">
          <label for="password">Пароль</label>
          <div class="lm__form-item-input">
            <input
              v-model="form.password.value"
              id="password"
              :type="showPassword ? 'text' : 'password'"
              placeholder="Введите пароль"
            />
            <a @click.prevent="showPassword = !showPassword" class="password__show-btn" href="#">
              <EyeIcon v-if="showPassword" />
              <EyeCloseIcon v-else />
            </a>
            <span class="lm__form-item-message" v-if="form.password.message">
              {{ form.password.message }}
            </span>
          </div>
        </div>
        <div
          class="lm__form-item form__item-password"
          :class="{ error: !!form.password_confirmation.message }"
        >
          <label for="password">Подтвердите пароль</label>
          <div class="lm__form-item-input">
            <input
              v-model="form.password_confirmation.value"
              id="password_confirmation"
              :type="showConfirmationPassword ? 'text' : 'password'"
              placeholder="Введите пароль"
            />
            <a
              @click.prevent="showConfirmationPassword = !showConfirmationPassword"
              class="password__show-btn"
              href="#"
            >
              <EyeIcon v-if="showConfirmationPassword" />
              <EyeCloseIcon v-else />
            </a>
            <span class="lm__form-item-message" v-if="form.password_confirmation.message">
              {{ form.password_confirmation.message }}
            </span>
          </div>
        </div>
        <button class="btn-blue btn--small" type="submit" :disabled="loading">
          <LoadingIndicator v-if="loading" />
          <template v-else>Сохранить</template>
        </button>
        <div class="lm__no-account">
          <span>Вспомнили пароль?</span>
          <a class="lm__register-link link__blue" href="#" @click.prevent="showLoginModal">Войти</a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import LogoIcon from "components/svg/Logo.vue";
import CloseIcon from "components/svg/CloseIcon.vue";
import LoadingIndicator from "components/LoadingIndicator.vue";
import LoginModal from "./LoginModal.vue";
import EyeIcon from "components/svg/EyeIcon.vue";
import EyeCloseIcon from "components/svg/EyeCloseIcon.vue";
import AlertModal from "@/views/sc/components/modals/AlertModal.vue";

export default {
  name: "RestorePasswordModal",
  props: {
    options: Object,
  },
  data() {
    return {
      loading: false,
      showPassword: false,
      showConfirmationPassword: false,
      form: {
        email: {
          value: null,
          message: null,
        },
        password: {
          value: null,
          message: null,
        },
        password_confirmation: {
          value: null,
          message: null,
        },
      },
    };
  },
  methods: {
    showLoginModal() {
      this.$store.state._modals = [
        {
          component: LoginModal,
        },
      ];
    },
    clearMessages() {
      Object.keys(this.form).forEach((field) => {
        this.form[field].message = null;
      });
    },
    async submitForm() {
      if (!this.loading) {
        this.clearMessages();
        this.loading = true;
        const fd = new FormData();
        fd.append("token", this.options.token);
        Object.keys(this.form).forEach((key) => {
          if (this.form[key].value !== null) fd.append(key, this.form[key].value);
        });
        await this.$store
          .dispatch("POST_RESET_PASSWORD", fd)
          .then(({ data }) => {
            history.pushState({}, null, this.$route.path);

            this.$store.state._modals = [
              {
                component: AlertModal,
                options: {
                  message: data.message,
                  callback: () => {
                    this.$store.state._modals = [
                      {
                        component: LoginModal,
                      },
                    ];
                  },
                },
              },
            ];
            this.loading = false;
          })
          .catch(({ response }) => {
            this.loading = false;
            Object.keys(response.data.errors).forEach((message) => {
              Object.keys(this.form).forEach((field) => {
                if (field === message) {
                  this.form[field].message = response.data.errors[message][0];
                }
              });
            });
          });
      }
    },
  },
  components: {
    EyeCloseIcon,
    EyeIcon,
    LoadingIndicator,
    CloseIcon,
    LogoIcon,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/loginModal.styl"
</style>
