<template>
  <main class="main home">
    <div class="container">
      <div class="main__inner">
        <HomeTapeComponent :list="tapes" />
        <section class="section news">
          <div class="news__inner">
            <div class="row">
              <div v-if="showAside" class="col-12 news__side">
                <div class="title__header">
                  <h2 class="title">Новости</h2>
                  <router-link class="news__side-show-all" :to="{ name: 'news' }">
                    <span>Все новости</span>
                  </router-link>
                </div>
                <div class="news__side-wrap">
                  <HomePageNewsSlider :news="newsMayor" />
                  <HomePageNewsSliderMobile :news="allNews" />
                  <HomePageNewsList :news="newsCity" />
                </div>
              </div>
              <UnderMessagesBannersSlider :banners="banners_under_messages" />
              <HomePageMessagesList :messages="messages" />
              <HomePageBannersSlider :banners="banners" id="under_messages" />
            </div>
          </div>
        </section>
        <div v-if="widget_under_services && isMounted" class="main-banner">
          <WidgetOG v-if="widget_under_services.iframe" :html="widget_under_services.iframe" />
          <a
            v-else-if="widget_under_services.head_img"
            :href="widget_under_services.link"
            target="_blank"
            :title="widget_under_services.title"
          >
            <img
              :src="widget_under_services.head_img | image($store.state.api)"
              :alt="widget_under_services.head_img | image_alt"
            />
          </a>
        </div>
        <section class="section services">
          <div class="services__inner">
            <div v-if="services && services.length" class="services__container">
              <div class="title__header">
                <h2 class="title">Популярные услуги</h2>
                <router-link class="news__side-show-all show__all" :to="{ name: 'services_catalog' }">
                  <span>Все услуги</span>
                </router-link>
              </div>
              <div class="services__content">
                <router-link
                  :to="{ name: 'service', params: { id: service_highlighted.id } }"
                  class="services__first"
                >
                  <div class="services__first-title">
                    {{ service_highlighted.title }}
                  </div>
                  <span class="services__first-type" v-if="service_highlighted.is_electronic_service">
                    Электронная услуга
                  </span>
                  <img
                    v-if="service_highlighted.head_img"
                    class="services__first-img"
                    :src="service_highlighted.head_img | image($store.state.api)"
                    :alt="service_highlighted.head_img | image_alt"
                  />
                </router-link>
                <div class="services__content-list">
                  <router-link
                    v-for="(item, i) in services"
                    :key="i"
                    class="services__content-item"
                    :to="{ name: 'service', params: { id: item.id } }"
                  >
                    <i class="services__content-item-icon" v-html="item.icon"></i>
                    <span class="services__content-item-info">
                      <span class="services__content-item-type" v-if="item.is_electronic_service">
                        Электронная услуга
                      </span>
                      <span class="services__content-item-title">{{ item.title }}</span>
                    </span>
                  </router-link>
                </div>
                <router-link class="btn btn--small btn-transpar_blue_dark" :to="{ name: 'services_catalog' }">
                  Все услуги
                </router-link>
              </div>
            </div>
            <div class="banner__big" v-if="widget && isMounted">
              <a v-if="widget.head_img" :href="widget.link" target="_blank">
                <img :src="widget.head_img | image($store.state.api)" :alt="widget.head_img | image_alt" />
              </a>
              <WidgetOG v-else-if="widget.iframe" :html="widget.iframe" />
            </div>
          </div>
        </section>
        <HomeCityDevComponent />
        <div v-if="widget_under_posters && isMounted" class="main-banner">
          <WidgetOG v-if="widget_under_posters.iframe" :html="widget_under_posters.iframe" />
          <a
            v-else-if="widget_under_posters.head_img"
            :href="widget_under_posters.link"
            target="_blank"
            :title="widget_under_posters.title"
          >
            <img
              :src="widget_under_posters.head_img | image($store.state.api)"
              :alt="widget_under_posters.head_img | image_alt"
            />
          </a>
        </div>
        <section v-if="lastPoster" class="section poster">
          <div class="poster__inner">
            <div class="row">
              <HomePosterMainComponent :poster="lastPoster" />
              <HomePosterTabsComponent :posters="posters" />
            </div>
          </div>
        </section>
        <section class="section news">
          <HomePageBannersSlider :banners="banners_under_poster" id="under_poster" />
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import HomePageNewsSlider from "./components/NewsSlider.vue";
import HomePageNewsSliderMobile from "./components/NewsSliderMobile.vue";
import HomePageNewsList from "./components/NewsList.vue";
import HomePageMessagesList from "./components/MessagesList.vue";
import HomePageBannersSlider from "./components/BannersSlider.vue";
import UnderMessagesBannersSlider from "./components/UnderMessagesBannersSlider.vue";
import HomeCityDevComponent from "./components/CityDev.vue";
import HomePosterMainComponent from "./components/PosterMain.vue";
import HomePosterTabsComponent from "./components/PosterTabs.vue";
import HomeTapeComponent from "./components/HomeTapeComponent.vue";
import ResetPasswordModal from "components/modals/ResetPasswordModal.vue";
import WidgetOG from "components/WidgetOG.vue";

export default {
  name: "HomePage",
  async asyncData({ store }) {
    await store.dispatch("GET_HOME_PAGE");
  },
  components: {
    HomePosterTabsComponent,
    HomePosterMainComponent,
    HomeCityDevComponent,
    HomePageBannersSlider,
    UnderMessagesBannersSlider,
    HomePageMessagesList,
    HomePageNewsList,
    HomePageNewsSlider,
    HomeTapeComponent,
    HomePageNewsSliderMobile,
    WidgetOG,
  },
  data() {
    return {
      isMounted: false,
    };
  },
  mounted() {
    this.isMounted = true;
    let url = new URL(location.href);
    let restoreToken = url.searchParams.get("restoreToken");
    if (restoreToken) {
      this.$store.state._modals = [
        {
          component: ResetPasswordModal,
          options: {
            token: restoreToken,
          },
        },
      ];
    }
  },
  computed: {
    posters() {
      return this.$store.state.home_page?.posters
        ? this.$store.state.home_page?.posters.filter((item) => {
            return item.data && item.data.length;
          })
        : [];
    },
    lastPoster() {
      return this.$store.state.home_page?.poster_last || null;
    },
    service_highlighted() {
      return this.$store.state.home_page?.service_highlighted || {};
    },
    services() {
      return this.$store.state.home_page?.services?.data || [];
    },
    messages() {
      return this.$store.state.home_page?.messages?.data || [];
    },
    banners() {
      if (this.$store.state.home_page?.banners?.data) {
        return this.$store.state.home_page.banners.data.filter((i) => i.head_img || i.iframe);
      }
      return [];
    },
    banners_under_messages() {
      if (this.$store.state.home_page?.banners_under_messages?.data) {
        return this.$store.state.home_page.banners_under_messages.data.filter((i) => i.head_img || i.iframe);
      }
      return [];
    },
    banners_under_poster() {
      if (this.$store.state.home_page?.banners_under_poster?.data) {
        return this.$store.state.home_page.banners_under_poster.data.filter((i) => i.head_img || i.iframe);
      }
      return [];
    },
    widget() {
      return this.$store.state.home_page?.widget || null;
    },
    newsMayor() {
      return this.$store.state.home_page?.news_mayor?.data || [];
    },
    newsCity() {
      return this.$store.state.home_page?.news_city?.data || [];
    },
    allNews() {
      return this.newsMayor.concat(this.newsCity);
    },
    showAside() {
      return this.newsMayor.length || this.newsCity.length;
    },
    widget_under_services() {
      return this.$store.state.home_page?.widget_under_services || null;
    },
    widget_under_posters() {
      return this.$store.state.home_page?.widget_under_posters || null;
    },
    tapes() {
      let tapes =
        this.$store.state.home_page?.tapes && this.$store.state.home_page.tapes.length
          ? [...this.$store.state.home_page.tapes]
          : [];
      return tapes.sort((a, b) => {
        if (a.position < b.position) {
          return -1;
        }
        if (a.position > b.position) {
          return 1;
        }
        return 0;
      });
    },
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
@import "~@/styles/mixins/textcut.styl"
@import "~@/styles/parts/swiper.styl"
@import "~@/styles/parts/home/index.styl"

.main-banner {
  padding 0 32px
  +below(1200px) {
    padding 0 24px
  }
  +below(380px) {
    padding 0 16px
  }

  img
  iframe {
    width 100%
    overflow hidden
  }

  > a {
    +below(768px) {
      display none
    }
  }
}
</style>
