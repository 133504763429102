<template>
  <div v-if="poster.id" class="col-5 poster__main">
    <h2 class="poster__title">
      <router-link :to="{ name: 'posters' }"> Афиша </router-link>
    </h2>
    <div class="poster__main_item">
      <router-link :to="{ name: 'poster_item', params: { id: poster.id, url: poster.link } }">
        <div class="poster__main_img">
          <div class="poster__main_img__content">
            <img :src="poster.head_img | image($store.state.api)" :alt="poster.head_img | image_alt" />
          </div>
        </div>
      </router-link>
      <div class="poster__info">
        <a href="#">
          <div class="poster__category" v-if="poster.category && poster.category.title">
            {{ poster.category.title }}
          </div>
        </a>
        <div class="poster__date" v-if="poster.date_publication">
          <time :datetime="poster.date_publication | robotDate">
            {{ poster.date_publication | humanDatePosterRu(poster.date_end) }}
          </time>
        </div>
      </div>
      <div class="poster__main_title" v-if="poster.title">
        <router-link :to="{ name: 'poster_item', params: { id: poster.id, url: poster.link } }">
          {{ poster.title }}
        </router-link>
      </div>
    </div>
    <router-link :to="{ name: 'posters' }" class="btn btn-transpar_blue_dark"> Все события </router-link>
  </div>
</template>

<script>
export default {
  name: "HomePosterMainComponent",
  props: {
    poster: Object,
  },
};
</script>
