<template>
  <ul class="news__list" v-if="news">
    <li :key="index" v-for="(item, index) in news">
      <div class="_item__header">
        <a href="#" v-if="item.category && item.category.title" class="_item__category news__city__color">
          {{ item.category.title }}
        </a>
        <div class="_item__time">
          <time :datetime="item.date_publication | robotDate">
            {{ item.date_publication | humanDateRu }}
          </time>
        </div>
      </div>
      <router-link :to="{ name: 'news_item', params: { id: item.id, url: item.link } }" class="_item__text">
        {{ item.title }}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "HomePageNewsList",
  props: {
    news: Array,
  },
};
</script>
