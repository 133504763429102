<template>
  <div class="col-7 poster__tabs">
    <h2 class="poster__title">
      <router-link :to="{ name: 'posters' }"> Афиша </router-link>
    </h2>
    <ul class="poster__tabs__list">
      <li :key="index" v-for="(item, index) in posters">
        <div class="poster__tabs__list-item" v-if="item.data.length">
          <a @click.prevent="setActive(index)" :class="{ active: activeTab === index }">
            {{ item.data[0] ? item.data[0].category.title : "" }}
          </a>
        </div>
      </li>
    </ul>

    <div v-for="(item, index) in posters" :key="'a' + index" class="slider__mobile poster__content-slider">
      <div v-show="activeTab === index">
        <swiper :options="postersOptions">
          <swiper-slide :key="'b' + k" v-for="(poster, k) in item.data">
            <div class="slide__mobile">
              <router-link
                :to="{ name: 'poster_item', params: { id: poster.id, url: poster.link } }"
                class="slide__news-item slide__posters-item"
                :class="{ no_image: !poster.head_img }"
              >
                <div v-if="poster.head_img" class="slide__news-img">
                  <div class="img__container">
                    <img
                      :src="poster.head_img | image($store.state.api)"
                      :alt="poster.head_img | image_alt"
                    />
                  </div>
                </div>
                <span v-if="poster.title" class="slide__news-title">{{ poster.title }}</span>
                <span class="slide__news-bottom">
                  <span v-if="poster.category && poster.category.title" class="slide__news-category">
                    {{ poster.category.title }}
                  </span>
                  <span class="slide__news-date">
                    <time :datetime="poster.date_publication | robotDate">
                      {{ poster.date_publication | humanDateRu }}
                    </time>
                  </span>
                </span>
              </router-link>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <div class="poster__tabs__content-wrap" :key="'c' + index" v-for="(item, index) in posters">
      <div v-show="activeTab === index" class="poster__tabs__content">
        <div class="poster__tabs__content__item" :key="k" v-for="(poster, k) in item.data">
          <div class="poster__tabs__content__img">
            <div class="img__content" v-if="poster.head_img">
              <div class="img__upper__layer"></div>
              <img :src="poster.head_img | image($store.state.api)" :alt="poster.head_img | image_alt" />
            </div>
          </div>
          <div class="poster__tabs__content__wrap" :class="{ no_image: !poster.head_img }">
            <div v-if="poster.title">
              <router-link
                class="poster__tabs__content__title"
                :to="{ name: 'poster_item', params: { id: poster.id, url: poster.link } }"
              >
                {{ poster.title }}
              </router-link>
            </div>
            <div class="poster__tabs__content__info">
              <a href="#" v-if="poster.category && poster.category.title">
                <div class="poster__tabs__content__category">
                  {{ poster.category.title }}
                </div>
              </a>
              <div class="poster__tabs__content__date" v-if="poster.date_publication">
                <time :datetime="poster.date_publication | robotDate">
                  {{ poster.date_publication | humanDatePosterRu(poster.date_end) }}
                </time>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-link :to="{ name: 'posters' }" class="btn btn-transpar_blue_dark">
      Все события
    </router-link>

    <!--    <router-link :to="{ name: 'posters' }" class="show__all-link poster__all_links-bottom">-->
    <!--      <span>Все мероприятия</span>-->
    <!--    </router-link>-->
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";

export default {
  name: "HomePosterTabsComponent",
  props: {
    posters: Array,
  },
  data() {
    return {
      activeTab: 0,
      postersOptions: {
        slidesPerView: 1.1,
        loop: false,
        clickable: true,
        speed: 800,
        spaceBetween: 16,
        breakpoints: {
          360: {
            slidesPerView: 1.5,
          },
          440: {
            slidesPerView: 1.8,
          },
          480: {
            slidesPerView: 2,
          },
        },
      },
    };
  },
  mounted() {
    this.activeTab = this.setCurrentTab();
  },
  methods: {
    setActive(tab) {
      this.activeTab = tab;
    },
    setCurrentTab() {
      return this.$store.state.home_page?.posters?.findIndex((poster) => {
        return poster.data.length;
      });
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
};
</script>
